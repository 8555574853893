import React, { } from 'react';
import { withRouter } from 'react-router-dom';

import { ReactSvgInjector } from "react-svg-injector";

import linkedinIcon from "../assets/img/icons/social/linkedin.svg";
import twitterIcon from "../assets/img/icons/social/twitter.svg";

function Footer(props) {

  return (
    <React.Fragment>
      <section className="text-white pb-0 o-hidden" style={{ padding: "0"}}>
        <div className="h-75 w-50 position-absolute bottom left" data-jarallax-element="-60">
          <div className="blob bottom left w-100 h-100 bg-white opacity-10"></div>
        </div>
        <div className="divider divider-bottom bg-primary-3"></div>
      </section>

      <footer className="bg-primary-3 text-white links-white pb-4 footer-1">
        <div className="container">
          <div className="row flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-between text-center text-lg-left">
            <div className="col-auto">
              <div className="d-flex flex-column flex-sm-row align-items-center text-small">
                <div className="text-muted">&copy; 2020 Sarunas Savicianskas
                </div>
              </div>
            </div>
            <div className="col-auto mt-3 mt-lg-0">
              <ul className="list-unstyled d-flex mb-0">
                <li className="mx-3">
                  <a target="_blank" href="https://linkedin.com/in/savicianskas" rel="noopener noreferrer" className="hover-fade-out">
                    <ReactSvgInjector src={linkedinIcon} className="icon icon-xs bg-white" />
                  </a>
                </li>
                <li className="mx-3">
                  <a target="_blank" href="https://twitter.com/s2sarunas" rel="noopener noreferrer" className="hover-fade-out">
                    <ReactSvgInjector src={twitterIcon} className="icon icon-xs bg-white" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}

export default withRouter(Footer);