import React, { } from 'react';
import { withRouter } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';

function Layout(props) {

  return (
    <React.Fragment>
      <Header
        {...props}
      />
      {props.children}
      <Footer
        {...props}
      />
    </React.Fragment>
  );
}

export default withRouter(Layout);