import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container
} from 'reactstrap';

import logoImg from "../assets/img/sarunas-logo.png";

function NavbarComponent(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  
  // INNER: navbar navbar-expand-lg bg-white navbar-light position-fixed
  // HOME: navbar navbar-expand-lg navbar-light

  return (
    <Navbar className="navbar navbar-expand-lg navbar-light">
      <Container>
        <NavbarBrand className="navbar-brand navbar-brand-dynamic-color fade-page" href="/">
          <img alt="Sarunas talking Product" src={logoImg} />
        </NavbarBrand>
        <NavbarToggler className="navbar-toggler" onClick={toggle} />
        <Collapse isOpen={isOpen} className="navbar-collapse order-3 order-lg-2 justify-content-lg-end" id="navigation-menu">
          <Nav navbar className="my-3 my-lg-0">
            <NavItem>
              <NavLink href="/">Articles</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/tag/product">#Product</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/tag/management">#Management</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/tag/leadership">#Leadership</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="mailto:sarunas@sarunas.co.uk">Contact</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default withRouter(NavbarComponent);