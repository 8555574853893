import React, { useEffect, lazy, Suspense } from "react";
import { withRouter } from "react-router-dom";

import Layout from './Layout/Layout';

import AOS from 'aos';
import 'aos/dist/aos.css';

import 'bootstrap/dist/css/bootstrap.css';
import './scss/theme.scss';

const Routes = lazy(() => import('./Routes'));

function App() {

  useEffect(() => {
    async function onLoad() {
      AOS.init({
        duration : 2000
      });
    }

    onLoad();
  }, []);

  const renderLoader = () => 
    <div className="loader">
      <div className="loading-animation"></div>
    </div>;
    
  return (
    <Suspense fallback={renderLoader()}>
      <Layout>
        <Routes appProps={{  }} />
      </Layout>
    </Suspense>
  );
}

export default withRouter(App);