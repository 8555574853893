import React, { } from 'react';
import { withRouter } from 'react-router-dom';

import Navbar from './Navbar';

function Header(props) {

  return (
    <div className="navbar-container bg-light">
      <Navbar />
    </div>
  );
}

export default withRouter(Header);